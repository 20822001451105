"use client";

import { useState } from "react";

export default function AdvertisementSection() {
  const [showTopLabel, setShowTopLabel] = useState(true);

  return (
    <>
      <div style={{ padding: "12px 70px", justifyContent: "space-between" }}>
        <div
          className={`top-label ${
            showTopLabel ? "d-block" : "d-none"
          } d-md-block ${showTopLabel ? "" : "d-md-none"}`}
        >
          <p
            className="text-center announcement-text"
            style={{
              lineHeight: "20px",
              marginBottom: "0",
              padding: "12px",
              letterSpacing: ".5px",
              fontWeight: "400",
            }}
          >
            <a href="/set" style={{ textDecoration: "none", color: "#F8F7F4" }}>
              {" "}
              Bundle & Save $125.{"  "}
              <span style={{ textDecoration: "underline", color: "#F8F7F4" }}>
                Shop The Set
              </span>
              .
            </a>
          </p>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 992px) {
          .top-label {
            padding-right: 20px;
          }
          .position-absolute {
            top: 10px;
            right: 10px;
          }
        }

        @media (max-width: 480px) {
          .top-label {
            padding-right: 12px;
          }
          .position-absolute {
            top: 15px;
            right: 15px;
          }
          .announcement-text {
            font-size: 14px;
          }
        }

        @media (max-width: 768px) {
          .top-label {
            padding-right: 25px;
          }
          .position-absolute {
            top: 12px;
            right: 12px;
          }
          .announcement-text {
            font-size: 14px;
          }
        }
        @media (max-width: 667px) {
          .announcement-text {
            font-size: 13px;
          }
        }
      `}</style>
    </>
  );
}
